/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  ClickAwayListener,
  Grid,
  Rating,
  Typography,
} from "@mui/material";
import bluechip from "../../images/icon-bluechip.svg";
import marketcapmillion from "../../images/icon-million.svg";
import marketcapbillion from "../../images/icon-billion.svg";
import marketcaptrillion from "../../images/icon-trillion.svg";
import dividend from "../../images/icon-dividend.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Tooltip from "@mui/material/Tooltip";
import { CompanyProfile } from "../../models/StockProfile";
import StockHistoryChart from "./stock-history-chart";
import StockProfileAccordians from "./stock-profile-accordians";
import useState from "react-usestateref";
import StockProfileNewsSlider from "./stock-profile-news-slider";
import appUtils from "../../shared/utils";
import StockPeers from "./stock-peers";
import AddRemoveFavourite from "../common/addRemoveFavourite";
import { ChartToolTip } from "./model/chart-tooltip-change.model";
import React from "react";
import { TimeMachine } from "./timeMachine";
import { HelpCenter, HelpOutline, HelpRounded } from "@mui/icons-material";

const StockProfile = (props: any) => {
  const { formatToTwoDecimal, stockIconToolTip } = appUtils();

  const [showRatingTooltip, setShowRatingTooltip] = useState<boolean>(false);
  const stockProfileInfo = props?.stock as CompanyProfile;
  const [isFavourite, setIsFavourite] = useState(stockProfileInfo?.isFavorite);
  const [investmentAmount, setInvestmentAmount] = useState<number>(1000);
  const [chartToolTip, setChartToolTip] = useState<ChartToolTip>({
    label: new Date().toLocaleString(),
    formattedValue: "0",
  });

  const isBlueChipCompany = () => {
    return (
      stockProfileInfo &&
      stockProfileInfo?.constituent &&
      stockProfileInfo?.constituent?.indexOf("DowJones") > -1
    );
  };

  const investmentAmountChange = ($event: any) => {
    if (!$event?.target?.value) {
      return;
    }
    setInvestmentAmount(parseFloat($event?.target?.value));
  };

  const addStockToFavourite = () => {
    setIsFavourite(true);
  };

  const removeStockFromFavourite = () => {
    setIsFavourite(false);
  };

  function onToolTipChange(data: ChartToolTip) {
    if (
      chartToolTip?.label !== data?.label ||
      chartToolTip?.formattedValue !== data?.formattedValue
    ) {
      setChartToolTip(data);
    }
  }

  const getValuationToolTipText = () => {
    let toolTipText = "";
    let buttonItem = <Box sx={{ color: "primary" }}></Box>;
    if (stockProfileInfo?.annualRatios?.priceEarningsToGrowthRatio > 1) {
      buttonItem = <Box sx={{ color: "red" }}>Overvalued</Box>;
      toolTipText =
        "The stock is overvalued (with PEG ratio " +
        stockProfileInfo?.annualRatios?.priceEarningsToGrowthRatio.toFixed(5) +
        ") when considering its future earning projection which is on the lower side. We estimate this using PEG Ratio which when higher than 1 is considered to be overvalued.";
    } else if (
      stockProfileInfo?.annualRatios?.priceEarningsToGrowthRatio === 1
    ) {
      buttonItem = <Box sx={{ color: "primary" }}>Valued right</Box>;
      toolTipText =
        "The stock is rightly valued (with PEG ratio " +
        stockProfileInfo?.annualRatios?.priceEarningsToGrowthRatio.toFixed(5) +
        ") when considering its future earning projection. We estimate this using PEG Ratio which when equal to 1 is considered to be rightly valued.";
    } else if (
      stockProfileInfo?.annualRatios?.priceEarningsToGrowthRatio < 1 &&
      stockProfileInfo?.annualRatios?.priceEarningsToGrowthRatio > 0
    ) {
      buttonItem = <Box sx={{ color: "green" }}>Undervalued</Box>;
      toolTipText =
        "The stock is undervalued (with PEG ratio " +
        stockProfileInfo?.annualRatios?.priceEarningsToGrowthRatio.toFixed(5) +
        ")  when considering its future earning projection which is on the higher side. We estimate this using PEG Ratio which when lower than 1 is considered to be undervalued.";
    } else {
      buttonItem = <Box sx={{ color: "primary" }}>Value Not Ascertained</Box>;
      toolTipText =
        "The stock value could not be ascertained since PEG ratio is" +
        stockProfileInfo?.annualRatios?.priceEarningsToGrowthRatio.toFixed(5) +
        ")  . We estimate this using PEG Ratio which when equal to or lesser than 0 indicates their present income is negative or their future earnings is predicted to be in loss.";
    }
    return (
      <Tooltip title={toolTipText} arrow>
        {buttonItem}
      </Tooltip>
    );
  };

  const ratingToolTip = `Stock Rating - "Following are the ratings from High to Low: S+, S, S-, A+, A, A-, B+, B, B-, C+, C, C-, D+, D, D-"`;

  return (
    <>
      <Grid container>
        <Grid item={true} xs={12}>
          <div>
            <Grid container>
              <Grid item={true} xs={12} md={6} lg={8}>
                <div className="d-flex align-items-center justify-content-center flex-column flex-md-row justify-content-md-start mb-3">
                  <div className="flex-shrink-0">
                    <img
                      src={stockProfileInfo?.image}
                      alt={stockProfileInfo?.companyName}
                      className="object-fit-contain"
                      width="120"
                      height="120"
                    />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <div className="d-flex align-items-center gap-2 my-2 justify-content-start">
                      <Tooltip
                        title={stockProfileInfo?.companyName}
                        placement="bottom"
                        arrow
                      >
                        <div className="primary-header header-txt-truncate">
                          {stockProfileInfo?.companyName}
                        </div>
                      </Tooltip>
                      <AddRemoveFavourite
                        stock={stockProfileInfo}
                        backgroundColor={"#ffff"}
                        isFavorite={isFavourite}
                        addToFavouriteStock={addStockToFavourite}
                        unFavouriteStock={removeStockFromFavourite}
                      ></AddRemoveFavourite>
                    </div>
                    <ul className="list-inline mb-0 text-center text-md-start">
                      {isBlueChipCompany() && (
                        <li className="list-inline-item">
                          <Tooltip
                            title={
                              stockProfileInfo?.companyName +
                              " is a BlueChip company"
                            }
                            arrow
                          >
                            <img src={bluechip} alt="" width="32" height="32" />
                          </Tooltip>
                        </li>
                      )}
                      {stockProfileInfo?.LastDiv > 0 && (
                        <li className="list-inline-item">
                          <Tooltip
                            title={
                              stockProfileInfo?.companyName + "pays dividends!"
                            }
                            arrow
                          >
                            <img src={dividend} alt="" width="32" height="32" />
                          </Tooltip>
                        </li>
                      )}
                      <li className="list-inline-item">
                        {stockProfileInfo?.MktCap > 999999999999 && (
                          <Tooltip
                            title={stockIconToolTip(stockProfileInfo)}
                            arrow
                          >
                            <img
                              src={marketcaptrillion}
                              alt=""
                              width="32"
                              height="32"
                            />
                          </Tooltip>
                        )}
                        {stockProfileInfo?.MktCap > 1000000000 &&
                          stockProfileInfo?.MktCap < 999999999999 && (
                            <Tooltip
                              title={stockIconToolTip(stockProfileInfo)}
                              arrow
                            >
                              <img
                                src={marketcapbillion}
                                alt=""
                                width="32"
                                height="32"
                              />
                            </Tooltip>
                          )}
                        {stockProfileInfo?.MktCap > 999999 &&
                          stockProfileInfo?.MktCap < 1000000 && (
                            <Tooltip
                              title={stockIconToolTip(stockProfileInfo)}
                              arrow
                            >
                              <img
                                src={marketcapmillion}
                                alt=""
                                width="32"
                                height="32"
                              />
                            </Tooltip>
                          )}
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
              <Grid item={true} xs={12} md={6} lg={4}>
                <div className="d-flex flex-column justify-content-center justify-content-md-end justify-content-lg-end align-items-center align-items-md-end align-items-ld-end">
                  <div className="primary-header mb-1">
                    {formatToTwoDecimal(stockProfileInfo?.Price)}
                    {stockProfileInfo?.currency}
                  </div>
                  <div className="stock-value-header mb-1">
                    {stockProfileInfo?.Changes > 0 && (
                      <span className="positive">
                        {formatToTwoDecimal(stockProfileInfo?.Changes)}
                        {stockProfileInfo?.currency} (+
                        {formatToTwoDecimal(
                          stockProfileInfo?.ChangesPercentage
                        )}
                        %)
                      </span>
                    )}
                    {stockProfileInfo?.Changes < 0 && (
                      <span className="negative">
                        {stockProfileInfo?.Changes} {stockProfileInfo?.currency}
                        (
                        {formatToTwoDecimal(
                          stockProfileInfo?.ChangesPercentage
                        )}
                        %)
                      </span>
                    )}
                  </div>
                  <div className="secondary-header mb-1">
                    <span>{stockProfileInfo?.exchangeShortName}</span> :
                    <span>{stockProfileInfo?.Symbol}</span>
                  </div>
                  {getValuationToolTipText()}
                </div>
              </Grid>
            </Grid>

            <div className="mb-1">
              <StockHistoryChart
                symbol={"'" + stockProfileInfo?.Symbol + "'"}
                currency={stockProfileInfo?.currency}
                onToolTipChange={onToolTipChange}
              ></StockHistoryChart>
            </div>
            <div className="d-flex justify-content-center">
              <div className="d-flex justify-content-center align-items-center">
                <div className="flex-grow-1 ms-3">
                  <TimeMachine
                    currentStockPrice={stockProfileInfo?.Price}
                    chartToolTip={chartToolTip}
                    currency={stockProfileInfo?.currency}
                  />
                </div>
              </div>
            </div>
            <div className="mb-3 mt-3">
              <Grid container sx={{ justifyContent: "space-between" }}>
                <Grid item xs={12} className="mb-2">
                  <Typography variant="h5">About Us </Typography>
                </Grid>
                <Grid container item xs spacing={1}>
                  <Grid
                    item
                    container
                    direction="column"
                    xs={"auto"}
                    sx={{ alignItems: "flex-end" }}
                  >
                    <span>CEO:</span>
                    <span>Industry:</span>
                    <span>Sector:</span>
                    <span>Ipo Date:</span>
                  </Grid>
                  <Grid item container direction="column" xs="auto">
                    <span>{stockProfileInfo?.CEO}</span>
                    <span>{stockProfileInfo?.industry}</span>
                    <span>{stockProfileInfo?.sector}</span>
                    <span>{stockProfileInfo?.ipoDate}</span>
                  </Grid>
                </Grid>
                <Grid container item xs="auto">
                  <Grid container item xs={12} spacing={1}>
                    <Grid
                      item
                      container
                      direction="column"
                      xs={"auto"}
                      sx={{ alignItems: "flex-end" }}
                    >
                      <div>
                        <ClickAwayListener onClickAway={() => setShowRatingTooltip(false)}>
                          <span>
                            <Tooltip
                              open={showRatingTooltip}
                              title={ratingToolTip}
                              arrow
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              slotProps={{ popper: { disablePortal: true } }}
                            >
                              <HelpOutline
                                onClick={() => setShowRatingTooltip(true)}
                                sx={{ fontSize: 18 }}
                                className="pr-1"
                                color="info"
                              />
                            </Tooltip>
                            Stock Ratings:
                          </span>
                        </ClickAwayListener>
                      </div>
                    </Grid>
                    <Grid item container direction="column" xs="auto">
                      <Typography variant="subtitle2" component="span">
                        {stockProfileInfo?.rating}
                      </Typography>                    
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <div className="primary-txt-16 mb-3 mt-3">
                {stockProfileInfo?.description}
              </div>
              <Typography variant="h5" className="mb-2">
                Fundamentals
              </Typography>
              <div className="primary-txt-16 mb-3">
                Stuff you absolutely must know about them
              </div>
            </div>
            <Grid container>
              <Grid item={true} xs={12}>
                {props?.companyOutlookLoader && (
                  <StockProfileAccordians
                    stock={stockProfileInfo}
                    companyOutlook={props?.companyOutLook}
                  />
                )}
              </Grid>
            </Grid>
            {props?.peers?.length > 0 && (
              <Grid container justifyContent="center">
                <Grid item={true} xs={12}>
                  <StockPeers peers={props?.peers}></StockPeers>
                </Grid>
              </Grid>
            )}
            <Grid container justifyContent="center">
              <Grid item={true} xs={12}>
                <div className="slider-container variant-1">
                  <StockProfileNewsSlider
                    companyName={stockProfileInfo?.companyName}
                    symbol={"'" + stockProfileInfo?.Symbol + "'"}
                  ></StockProfileNewsSlider>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default StockProfile;
